<template>
  <b-card-code title="جدول المنتسبين" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button @click="reset()"> الغاء الفلتر </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="gS()"
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">فلتر</span>
              </b-button>
              <export-excel
                style="color: white; padding-top: 10px"
                class="btn btn-default"
                :data="items"
                :fields="exportFields"
                worksheet="My Worksheet"
                name="filename.xls"
              >
                استخراج ملف اكسل
              </export-excel>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="4">
          <b-form-group>
            <v-select
              v-model="search.department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.departments"
              placeholder="اختر القسم"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
    <div class="demo-vertical-spacing">
      <b-progress v-if="precentage !== 0" :value="precentage" max="100" />
    </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <!-- <template #cell(status)="data">
        <b-badge v-if="data.item.is_leaved == false" pill :variant="`success`">
          منتسب فعال
        </b-badge>
        <b-badge v-else pill :variant="`danger`"> مغادر </b-badge>
      </template> -->
      <template #cell(account)="data">
        <b-badge v-if="data.item.account == true" pill :variant="`success`">
          منتسب فعال
        </b-badge>
        <b-badge v-else pill :variant="`danger`"> مغادر </b-badge>
      </template>
      <template #cell(departments)="data">
        <b-badge
          v-for="stage in data.item.departments"
          :key="stage.id"
          pill
          :variant="`success`"
        >
          {{ stage.name }}
        </b-badge>
      </template>
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.modal-user-days
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              v-b-modal.modal-center
              @click="eRM(action.item)"
            >
              <feather-icon icon="AlertTriangleIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" v-b-modal.modal-user-uid
            @click="eSM(action.item)">
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle">بصمة</span>
            </b-button>
          </b-col>
          <!-- <b-col md="12">
            <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              :to="{ name: 'employee-documents-list-show', params: { id: action.item.id }}"
            >
              <feather-icon icon="FolderIcon" class="mr-50" />
            </b-button>
          </b-col> -->
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <b-modal
      id="modal-center"
      centered
      title="تعديل حالة منتسب"
      @ok="dM"
      ok-title="حفظ التعديلات"
    >
      <b-card-text>
        هل تود تغيير حالة هذا المنتسب "
        {{ this.editform.name }} " ?
      </b-card-text>
    </b-modal>
    <b-modal id="modal-user-days" centered title="تحديد عدد ايام الحضور في الاسبوع الواحد" @ok="uP" ok-title="حفظ البيانات">
      <b-card-text>
        <b-col md="12">
          <b-form-input id="input-valid1" v-model="days_of_week"
              placeholder="عدد الايام رقما فقط" />
        </b-col>
      </b-card-text>
    </b-modal>
    <b-modal id="modal-user-uid" centered title="تحديد رقم البصمة" @ok="uPUM" ok-title="حفظ الرقم">
      <b-card-text>
        <b-col md="12">
          <b-form-input id="input-valid1" v-model="presence_id"
              placeholder="رقم البصمة" />
        </b-col>
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BProgress,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
    BProgress,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      days_of_week:null,
      precentage: 0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      filter: null,
      filterOn: [],
      roles: [],
      teachers: [],
      department_id: "",
      presence_id: null,  
      search: {
        departments: [],
        department_id: "",
        role_id: "",
      },
      departments: [],
      exportFields: {
        TotalName: "name",
        Email: "email",
        Degree: "graduation_degree",
        Department: "department.name",
        Account: "account",
        Status: "is_leaved",
      },
      exportData: [
        {
          TotalName: "name",
          Email: "email",
          Degree: "graduation_degree",
          Department: "department.name",
          Account: `account`,
          Status: `is_leaved`,
        },
      ],
      fields: [
        {
          key: "name",
          label: "الاسم الكامل",
        },
        {
          key: "email",
          label: "الايميل",
        },
        {
          key: "days_of_week",
          label: "عدد ايام الدوام",
        },
        {
          key: "department.name",
          label: "القسم",
        },
        {
          key: "presence_id",
          label: "رقم البصمة",
        },
        {
          key: "account",
          label: "الحساب",
        },
        // {
        //   key: "status",
        //   label: "الحالة",
        // },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      editform: {
        id: null,
        user_id:null,
        name:""
      },
      selected: [],
      active: true,
    };
  },
  watch: {
    filter: function (val) {
      if (val == null) {
        this.filter = null;
      }
      this.gS();
    },
    watch: {
      "$route.params.id": {
        handler: function () {
          this.gS();
        },
      },
      "search.department_id": {
        handler: function () {
          if (val != null || val != "") {
            this.gS();
          }
        },
      },
      active: function (val) {
      console.log(val)
    },
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gS(), this.gSM();
  },
  methods: {
    async uP(){
      let data = new FormData();
      data.append("days_of_week", this.days_of_week);

      await this.axios
        .post(`users/setdays/${this.editform.user_id}`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS()
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage = 0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();

    },
    eSM(item) {
      this.editform.id = item.id;
    },
    rF(){
      this.days_of_week = null
      this.editform.id = null
      this.presence_id = null 
    },
    async gSM() {
      await this.axios
        .get(
          `since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&select=true`
        )
        .then((res) => {
          this.departments = [];
          this.search.departments = res.data.items;
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    resetCheckboxes() {
      this.selected = [];
    },
    rEF() {
      this.editform.id = "";
      this.editform.user_id = "";
    },
    async dM() {
      await this.axios
        .get(`status/${this.editform.user_id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your item is updated successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.rEF();
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.id = item.teacher_id;
      this.editform.user_id = item.id;
      this.editform.name = item.name;
    },
    dRM(item) {
      this.editform.name = item.full_name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async uPUM() {
      let data = new FormData();
      data.append("presence_id", this.presence_id);

      await this.axios
        .post(`users/setpresence/${this.editform.id}`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage = 0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async gS() {
      console.log(this.active)
      if (
        isNaN(this.search.department_id) &
        (this.search.department_id != "")
      ) {
        this.search.department_id = this.search.department_id["id"];
      }
      await this.axios
        .get(
          `employees?take=${this.perPage}&skip=${this.currentPage - 1}&search=${
            this.filter
          }&department=${this.search.department_id}&active=${
            this.active
          }&show=${this.$route.params.id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    checkDept() {
      console.log(this.search.department_id);
      console.log(this.department_id);
    },
    reset() {
      (this.search.department_id = ""), (this.search.role_id = "");
      this.filter = "";
      this.gS();
    },
  },
};
</script>
